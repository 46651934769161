// src/App.js
import React from 'react';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Navbar from './components/Navbar';
import Home from './components/Home';
import HornBook from './components/HornBook';
import Dashboard from './components/Dashboard';
import FileRepository from './components/FileRepository';
import TextRepository from './components/TextRepository';
import Spreadsheet from './components/Spreadsheet';

function App() {
  const [currentTab, setCurrentTab] = React.useState('home');

  const renderTab = () => {
    switch (currentTab) {
      case 'home':
        return <Home setCurrentTab={setCurrentTab} />;
      case 'HornBook':
        return <HornBook />;
      case 'TextRepository':
        return <TextRepository />;
      case 'FileRepository':
        return <FileRepository />;
      case 'Dashboard':
        return <Dashboard />;
      case 'Spreadsheet':
        return <Spreadsheet />;
      default:
        return <Home setCurrentTab={setCurrentTab} />;
    }
  };

  return (
    <AuthProvider>
      <ProtectedRoute>
        <div className="min-h-screen bg-gray-100">
          <Navbar currentTab={currentTab} setCurrentTab={setCurrentTab} />
          {renderTab()}
        </div>
      </ProtectedRoute>
    </AuthProvider>
  );
}

export default App;
